<template lang="javascript">
<h1>Fragments</h1>
<p>
  Fragments in Vue 3 now allow multiple root elements in the template tag of a
  component. This provides the developer with the option to avoid unnecessary
  wrapper elements, which can clutter up the DOM and make styles messier than
  they would otherwise need to be.
</p>
</template>

<script src="./Fragments.js"></script>

<style scoped lang="scss" src="./Fragments.scss">
</style>
