import { createStore } from 'vuex'

const state = {
  token: '',
  recommendationsInfo: false,
  createPlaylistTracks: []
}
const mutations = {

}
const actions = {

}
const getters = {

}

const store = createStore({
  state,
  mutations,
  actions,
  getters
})

export default store

