<template>
<div>
  <h1>{{ card }}</h1>
  <button @click="redirect">Click to read more...</button>
</div>
</template>

<script src="./info-card.js"></script>

<style lang="scss" src="./info-card.scss"></style>
