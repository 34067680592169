<template>
  <h1>Suspense</h1>
  <p>
    Suspense is a great new feature in Vue 3 that allows content to be displayed
    while an asynchronous component is initializing. This could be used in
    creating loading screens, displaying default data while API calls are being
    made, and hiding elements until all data is loaded.
  </p>
</template>

<script src="./async-component.js"></script>
<style lang="scss" scoped src="./async-component.scss">
</style>